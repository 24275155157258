import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import NavBar from "./Nav";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import Homeimg from "../Images/firstimage.svg";

const Home = () => {
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
  }, []);
  const gradientBackgroundStyle = {
    background: "#1967D2",
  };
  return (
    <React.Fragment>
      <section
        className="section pb-0 hero-section"
        id="home"
        style={gradientBackgroundStyle}
      >
        <div className="bg-overlay bg-overlay-pattern"></div>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={10} lg={8}>
              <div className="text-center text-light mt-4 mt-lg-5 pt-5">
                <div className="d-flex flex-column flex-sm-row align-items-center justify-content-center mb-4">
                  <div
                    className="btn rounded-pill get-started-btn mb-3 mb-sm-0"
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      border: "1px solid white",
                    }}
                  >
                    🏅 Request a live demo, now. Try our Alpha release V1.0.1
                    <i className="ri-arrow-right-line align-middle ms-1"></i>
                  </div>
                </div>
                <h1
                  className={`display-10 fw-bold mb-3 text-light lh-base custom-title ${
                    animate ? "animate-slide-in" : ""
                  }`}
                  style={{ fontFamily: "Lora, serif" }}
                >
                  Advanced AI Surveillance Analysis Solution.
                </h1>
                <p
                  className="lead text-break  text-light custom-lead"
                  style={{ fontFamily: "DM Sans, sans-serif" }}
                >
                  Explore AI-driven surveillance with SkyEyez: Communicate,
                  Alert, Query revolutionizing security with three key features.
                </p>
                <div className="d-flex justify-content-center align-items-center mt-3 mb-5">
                  <Form inline className="d-flex">
                    <Input
                      type="text"
                      placeholder="First Name"
                      className="me-2"
                      style={{ backgroundColor: "#FFFFFF", width: "30%" }}
                    />
                    <Input
                      type="email"
                      placeholder="Email"
                      className="me-2"
                      style={{ backgroundColor: "#FFFFFF", width: "40%" }}
                    />
                    <Button
                      className="btn btn-primary"
                      style={{ background: "#FF902A" }}
                      onClick={() =>
                        document
                          .getElementById("submit")
                          .scrollIntoView({ behavior: "smooth" })
                      }
                    >
                      Join Waitlist
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Row className="mt-2 mt-sm-2 mb-0 pt-sm-2 mb-sm-n3">
          <Col>
            <div className="home-img-container">
              <img src={Homeimg} className="img-fluid home-img" alt="Home" />
            </div>
          </Col>
        </Row>
      </section>
      <style jsx>{`
        @import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&family=DM+Sans:wght@400;700&display=swap");

        @keyframes slideIn {
          from {
            transform: translateY(20px);
            opacity: 0;
          }
          to {
            transform: translateY(0);
            opacity: 1;
          }
        }
        .animate-slide-in {
          animation: slideIn 0.8s ease-out forwards;
        }
        .get-started-btn {
          min-width: 150px;
          padding: 2px 10px;
          text-align: center;
        }
        .custom-title {
          font-size: 3rem;
          line-height: 1.2;
          opacity: 0;
        }
        .custom-lead {
          font-size: 1.2rem;
        }
        .home-img-container {
          position: relative;
          width: 100%;
          overflow: hidden;
          padding: 0 5%;
        }
        .home-img {
          width: 100%;
          height: auto;
          display: block;
          margin-bottom: -5%; /* Makes the image extend slightly below the component */
        }
        @media (max-width: 576px) {
          .custom-title {
            font-size: 2rem;
          }
          .custom-lead {
            font-size: 1rem;
          }
        }
      `}</style>
    </React.Fragment>
  );
};
export default Home;
