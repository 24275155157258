import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Sky from "../Images/SkyeyesLogo.svg";

const Footer = () => {
  const footerStyle = {
    background: "#1967D2",
    color: "#ffffff",
    paddingTop: "40px",
    paddingBottom: "40px",
  };

  const textCenter = {
    textAlign: "start",
  };

  const linkStyle = {
    color: "#ffffff",
    textDecoration: "none",
    display: "inline-block",
    marginBottom: "15px",
  };

  const linkColumnStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const curateLearnStyle = {
    color: "#FF7223",
  };

  return (
    <footer style={footerStyle}>
      <Container>
        <Row>
          <Col lg={4}>
            <div>
            <img src={Sky} alt="Swift Wings Logo" height="40" />
              <p style={textCenter}>© Copyright 2024.  All rights reserved.</p>
            </div>
          </Col>
          <Col lg={8}>
            <Row>
              <Col sm={4}>
                <div style={linkColumnStyle}>
                  {/* <a href="#home" style={linkStyle}>Community</a> */}
                  <a className="fw-bold" href="#portfolio" style={linkStyle}>COMPANY</a>
                  <a href="#contact" style={linkStyle}>About</a>
                  <a href="#faq" style={linkStyle}>Features</a>
                  <a href="#contact" style={linkStyle}>Works</a>
                  <a href="#faq" style={linkStyle}>career</a>
                
                </div>
              </Col>
              <Col sm={4}>
                <div style={linkColumnStyle}>
                  <a className="fw-bold" href="#about" style={linkStyle}>HELP</a>
                  <a href="https://ng.linkedin.com/in/daniel-ogunlolu-falcon" style={linkStyle} target="_blank" rel="noopener noreferrer">customer support</a>
                  <a href="#contact" style={linkStyle}>Delivery details</a>
                  <a href="#faq" style={linkStyle}>Terms & Conditions</a>
                  <a href="#contact" style={linkStyle}>Privacy Policy</a>
                </div>
              </Col>
              <Col sm={4}>
                <div style={linkColumnStyle}>
                  <a className="fw-bold" href="#about" style={linkStyle}>RESOURCES</a>
                  {/* <a href="#blog" style={linkStyle}>Blog</a> */}
                  <a href="https://ng.linkedin.com/in/daniel-ogunlolu-falcon" style={linkStyle} target="_blank" rel="noopener noreferrer">Free Ebooks</a>
                  <a href="#contact" style={linkStyle}>Development Tutorial</a>
                  <a href="#faq" style={linkStyle}>Terms & Conditions</a>
                  <a href="#contact" style={linkStyle}>Howto-Blog</a>
                 
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;