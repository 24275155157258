import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import IMG from "../Images/imageuse.svg";

const Services = () => {
  const cardStyle = {
    background:
      "radial-gradient(circle at center, rgba(29, 179, 251, 0.1) 0%, rgba(153, 129, 232, 0.1) 50%, rgba(195, 65, 197, 0.1) 100%)",
    borderRadius: "25px",
    overflow: "hidden",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  };

  return (
    <Fragment>
      <section id="services" style={{ background: "#FFFFFF" }}>
        <Container>
          <Row className="align-items-center">
            <div className="text-center text-dark mt-4 mb-5 pb-5 mt-lg-5 pt-5"></div>
          </Row>
          <Row className="gx-5">
            <Col
              md={6}
              sm={12}
              className="order-1 order-md-2 d-flex flex-column justify-content-center"
            >
              <h1 className="display-6 fw-bold fs-48 lh-base text-sm-center text-md-start lora-font">
                Communicate with your Security Cameras.
              </h1>
              <p className="mb-5" style={{ fontFamily: "DM Sans, sans-serif" }}>
                Step into a new era of interactive surveillance. With SkyEyez,
                your security cameras become a two-way communication channel.
                Address incidents, give instructions, or simply check in{" "}
                <span className="text-primary">–all in real-time.</span>
                Keep your space secure and responsive at your fingertips.
              </p>
            </Col>

            <Col md={6} sm={12} className="order-1 order-md-2">
              <img src={IMG} alt="" className="user-img w-100 mb-4 mb-md-3" />
            </Col>
          </Row>
        </Container>
      </section>
      <style jsx>{`
        @import url("https://fonts.googleapis.com/css2?family=Lora&display=swap");
        @import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

        .lora-font {
          font-family: "Lora", serif;
        }

        @media (max-width: 768px) {
          .row {
            flex-direction: column-reverse;
          }
          .col-md-6 {
            margin-bottom: 2rem;
          }
        }
      `}</style>
    </Fragment>
  );
};

export default Services;
