import React from "react";
import {
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import IMG from "../Images/securitycamera.svg";

const Services = () => {
  const [open, setOpen] = React.useState("0");

  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <React.Fragment>
      <section id="faq" style={{ background: "#FFFFFF" }}>
        <Container>
          <Row>
            <Col className="col-12">
              <div className="justify-content-between d-flex align-items-center mt-3 mb-4"></div>

              <Row className="justify-content-center mt-5">
                <Col lg={8}>
                  <div className="text-center pt-5 text-dark mb-5">
                    <h3 className="fw-bold">Frequently Asked Questions</h3>
                    <p className="mb-4 text-muted ff-secondary">
                      Everything you need to know about the product and billing.
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center pt-1 mb-5">
                <Col lg={8}>
                  <Accordion open={open} toggle={toggle}>
                    <AccordionItem>
                      <AccordionHeader targetId="1">
                        Is there a free trial available?
                      </AccordionHeader>
                      <AccordionBody className="text-muted" accordionId="1">
                        Yes, you can try us for free for 30 days. If you want,
                        we'll provide you with a free, personalized 30-minute
                        onboarding call to get you up and running as soon as
                        possible.
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="2">
                        Can I change my plan later?
                      </AccordionHeader>
                      <AccordionBody className="text-muted" accordionId="2">
                        Yes, you can change your plan at any time. Upgrades will be applied immediately, while downgrades will take effect at the start of the next billing cycle.
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="3">
                        What is your cancellation policy?
                      </AccordionHeader>
                      <AccordionBody className="text-muted" accordionId="3">
                        You can cancel your subscription at any time. Once cancelled, you'll have access to your account until the end of your current billing period.
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="4">
                        Can other info be added to an invoice?
                      </AccordionHeader>
                      <AccordionBody className="text-muted" accordionId="4">
                        Yes, you can add custom fields to your invoices. Please contact our support team for assistance with this.
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="5">
                        How does billing work?
                      </AccordionHeader>
                      <AccordionBody className="text-muted" accordionId="5">
                        We offer monthly and annual billing cycles. You'll be charged at the beginning of each cycle based on your chosen plan.
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="6">
                        How do I change my account email?
                      </AccordionHeader>
                      <AccordionBody className="text-muted" accordionId="6">
                        You can change your account email in your profile settings. After changing, you'll need to verify the new email address.
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col lg={6} className="text-center">
                  <img src={IMG} alt="Security Camera" style={{ maxWidth: '100%', height: 'auto' }} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
      <style jsx>{`
        .accordion-button::after {
          background-image: none !important;
          content: '+' !important;
          font-size: 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          margin-left: auto;
          border: 2px solid #000;
          border-radius: 50%;
          transition: all 0.3s ease;
        }
        .accordion-button:not(.collapsed)::after {
          content: '-' !important;
          background-color: #000;
          color: #fff;
        }
        .accordion-button:focus {
          box-shadow: none;
        }
      `}</style>
    </React.Fragment>
  );
};

export default Services;